import React from "react"
import Layout from "../retech/components/layout/Layout"
import { Button, InputGroup } from "react-bootstrap"
import { Helmet } from "react-helmet"

import { Link, graphql } from "gatsby"
export default function ErrorPage({ data }) {
    const hero = data.hero.nodes
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const urlimage = typeof window !== 'undefined' ? window.location.hostname : '';
    return (
        <Layout>
            <Helmet>
                <title>Retech | 404</title>

                <meta name="description" content={data.metadata.nodes[0].NotfoundMetaDescription} />
                <meta name="keywords" content={data.metadata.nodes[0].Notfoundkeywords} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={data.metadata.nodes[0].NotfoundMetaTitle} />
                <meta property="og:description" content={data.metadata.nodes[0].NotfoundMetaDescription} />
                <meta property="og:image" content={urlimage + "/logo.svg"} />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={url} />
                <meta property="twitter:title" content={data.metadata.nodes[0].NotfoundMetaTitle} />
                <meta property="twitter:description" content={data.metadata.nodes[0].NotfoundMetaDescription} />
                <meta property="twitter:image" content={urlimage + "/logo.svg"} />
            </Helmet>
            <div className="container-fluid px-0">
                {hero.map((node) => {
                    return node.Hero404
                        ? node.Hero404.map((q) => (
                            <>
                                <div
                                    className="show_bg_desktop d-none d-sm-block"
                                    style={{
                                        height: "391px",
                                        backgroundImage:
                                            "linear-gradient(349.66deg,#1f2025 4.11%,rgba(31,32,37,.3) 18.52%,rgba(31,32,37,0) 36.03%,rgba(31,32,37,0) 70.01%),linear-gradient(86deg,#000 4.11%,transparent 70.01%),url(" + q.HeroSectionImage + ")",
                                    }}
                                >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-6 pt-5 mt-4 pr-5">
                                                <h1 className="font-wight-boder">{q.HeroSectionTitle}</h1>
                                                <p className="text-white pb-4">
                                                    {q.HeroSectiondescription}
                                                </p>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Append>
                                                        <Link to="/">
                                                            <Button
                                                                variant="outline-secondary"
                                                                className="text-white"
                                                            >
                                                                {q.HeroSectionCTAText}{" "}
                                                            </Button>
                                                        </Link>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        ))
                        : null
                })}
                {hero.map((node) => {
                    return node.Hero404
                        ? node.Hero404.map((q) => (
                            <>
                                <div
                                    className="show_bg_desktop  d-block d-sm-none"
                                    style={{
                                        height: "700px",
                                        backgroundImage:
                                            "linear-gradient(349.66deg,#1f2025 4.11%,rgba(31,32,37,.3) 18.52%,rgba(31,32,37,0) 36.03%,rgba(31,32,37,0) 70.01%),linear-gradient(86deg,#000 4.11%,transparent 70.01%),url(" + q.HeroSectionImage + ")",
                                    }}
                                >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-6 pt-5 mt-4 pr-5">
                                                <h1 className="font-wight-boder">{q.HeroSectionTitle}</h1>
                                                <p className="text-white pb-4">
                                                    {q.HeroSectiondescription}
                                                </p>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Append>
                                                        <Link to="/">
                                                            <Button
                                                                variant="outline-secondary"
                                                                className="text-white"
                                                            >
                                                                {q.HeroSectionCTAText}{" "}
                                                            </Button>
                                                        </Link>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        ))
                        : null
                })}

            </div>
        </Layout>
    )
}

export const query = graphql`
query{


hero:  allHeroJson {
    nodes {
      Hero404 {
        HeroSectionCTALink
        HeroSectionCTAText
        HeroSectionImage
        HeroSectionTitle
        HeroSectiondescription
      }
    }
  }
  metadata:  allMetadataJson(filter: {NotfoundMetaTitle: {ne: null}}) {
    nodes {
      NotfoundMetaDescription
      NotfoundMetaTitle
      Notfoundkeywords
    }
  }
}
`